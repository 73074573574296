import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { Fragment } from 'react/jsx-runtime';
import Columns from '../components/containers/Columns';
import ScreenContainer from '../components/containers/ScreenContainer';
import SectionContainer from '../components/containers/SectionContainer';
import Button, { ButtonTypes } from '../forms/Button';
import Form from '../forms/Form';
import RegisteredDateField from '../forms/registeredFields/RegisteredDateField';
import RegisteredRadioGroup from '../forms/registeredFields/RegisteredRadioGroup';
import RegisteredSelect from '../forms/registeredFields/RegisteredSelect';
import RegisteredTextField from '../forms/registeredFields/RegisteredTextField';
import { RequestData } from '../mockData/mockRequestData';
import {
  defaultValues,
  surrogateNoticeSchema,
} from '../utils/surrogateNotice.utils';
import { zodResolver } from '@hookform/resolvers/zod';
import RegisteredCheckboxGroup from '../forms/registeredFields/RegisteredCheckboxGroup';
import { useCallback, useEffect, useState } from 'react';
import {
  dataverseFetch,
  dataverseFetchWithNotification,
} from '../api/methods/http';
import isEqual from 'lodash/isEqual';
import { GpaAPI, useApi } from '../api/APIContext';
import { DemandeGPAClientPayload } from '../api/methods/demandes';
import {
  determineSignatureState,
  getDemandeParents,
  uploadPDFForm,
  waitSeconds,
} from '../utils/authFormCommon.utils';
import BackButton from '../components/BackButton';
import Pill from '../components/Pill';
import RegisteredCheckbox from '../forms/registeredFields/RegisteredCheckbox';
import { getFormattedDateTime } from '../lib/dateformat';
import { useFetchedSelectOptions } from '../forms/hooks/useFetchedSelectOptions';

interface SurrogateNoticeProps {
  requestData: RequestData;
}
interface SurrogateNoticeForm {
  signed_pi1?: string;
  signed_pi2?: string;
  event: 'surrogatePregnant' | 'terminatedPregnancy' | 'birth' | '';
  expectedDeliveryDate?: string;
  sperm?: Array<'parent' | 'parent1' | 'parent2' | 'donor'> | [];
  egg?: 'parent1' | 'parent2' | 'surrogate' | 'donor' | null;
  terminationDate?: string;
  infantFields?: Array<{
    firstName: string;
    lastName: string;
    sex: 'female' | 'male' | 'other' | null;
    dob: string;
  }>;
  numberOfChildren: number;
  municipality?: string;
  state?: string;
  expectedReturnToQuebec?: string;
  signConsent: boolean;
}

const surrogateNoticeFormTypesGetter = (api: GpaAPI) =>
  api.demandes.getSurrogateNoticeFromTypes();

const SurrogateNotice = ({ requestData }: SurrogateNoticeProps) => {
  const { i18n, t } = useTranslation();
  const api = useApi();
  const [demande, setDemande] = useState<DemandeGPAClientPayload>();
  const { selfParent, otherParent } = getDemandeParents(demande);
  const [surrogateNotice, setSurrogateNotice] = useState<SurrogateNoticeForm>(
    defaultValues as SurrogateNoticeForm
  );

  const signatureState = determineSignatureState(demande, surrogateNotice);
  const { selfParentHasSigned, otherParentHasSigned, someoneHasSigned } =
    signatureState;

  const getSelectOptions = useFetchedSelectOptions(
    surrogateNoticeFormTypesGetter
  );

  const {
    handleSubmit,
    register,
    reset,
    watch,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: surrogateNotice,
    resolver: zodResolver(surrogateNoticeSchema(i18n.language)),
    shouldUnregister: true,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'infantFields',
    shouldUnregister: true,
  });

  const submitFn = async (data: any) => {
    const response = await dataverseFetchWithNotification<SurrogateNoticeForm>(
      `/demandes/${demande?.id}/surrogateNotice`,
      {
        method: 'PUT',
        body: JSON.stringify(data),
      }
    );
    console.log(response);
    setSN(response);
  };

  const instructions: { heading: string; text: string }[] = t(
    'surrogateNotice.instructions.items',
    {
      returnObjects: true,
    }
  );

  const numberOfChildren = watch('numberOfChildren');

  useEffect(() => {
    if (fields.length < numberOfChildren) {
      append({ lastName: '', firstName: '', sex: null, dob: '' });
    } else if (fields.length > watch('numberOfChildren')) {
      remove(fields.length - 1);
    }
  }, [append, fields.length, numberOfChildren, remove, watch]);

  const setSN = useCallback(
    async (sn: SurrogateNoticeForm) => {
      setSurrogateNotice(sn);
      reset(sn);
      if (!demande) {
        return;
      }
      const newSignatureState = determineSignatureState(demande, sn);
      const { numberOfMissingSignatures } = newSignatureState;

      if (numberOfMissingSignatures === 0) {
        await waitSeconds(0.1);
        await uploadPDFForm(
          '.exportable-form',
          api,
          demande,
          t('surrogateNotice.heading'),
          5
        );
        window.location.href = `/signed?demandeId=${demande.id}`;
      }
    },
    [api, demande, reset, t]
  );

  useEffect(() => {
    api?.demandes
      .getActivedemande()
      .then((d) => {
        if (!isEqual(d, demande)) {
          setDemande(d);
          if (!d) {
            return (window.location.href = '/portal');
          }
          dataverseFetch<SurrogateNoticeForm>(
            `/demandes/${d.id}/surrogateNotice`
          ).then((d) => {
            if (Object.keys(d).length !== 0) {
              setSN(d);
            }
          });
        }
      })
      .catch(() => {
        return (window.location.href = '/portal');
      });
  }, [api, demande, setSN]);

  return (
    <ScreenContainer>
      <SectionContainer>
        <h1 className='heading1'>{t('surrogateNotice.heading')}</h1>
        {/*<RequestDetails requestData={requestData} />*/}
      </SectionContainer>
      <SectionContainer>
        <h2 className='heading2'>
          {t('surrogateNotice.instructions.heading')}
        </h2>

        <p className='!mb-5'>{t('surrogateNotice.instructions.paragraph')}</p>

        {instructions.map((instruction, i) => (
          <Fragment key={i}>
            <ReactMarkdown className='whitespace-pre-wrap'>
              {instruction.heading}
            </ReactMarkdown>
            <ReactMarkdown className='!mb-5 ml-5 whitespace-pre-wrap'>
              {instruction.text}
            </ReactMarkdown>
          </Fragment>
        ))}
        <ReactMarkdown className='whitespace-pre-wrap'>
          {t('surrogateNotice.instructions.notes')}
        </ReactMarkdown>
      </SectionContainer>
      <div className='exportable-form'>
        <Form onSubmit={handleSubmit(submitFn)}>
          <fieldset disabled={someoneHasSigned}>
            <SectionContainer>
              <h2 className='heading2'>
                {t('surrogateNotice.eventToCommunicate.heading')}
              </h2>
              <RegisteredRadioGroup
                required
                name={'event'}
                register={register}
                options={[
                  {
                    value: 'surrogatePregnant',
                    label: t(
                      'surrogateNotice.eventToCommunicate.options.surrogatePregnant'
                    ),
                  },
                  {
                    value: 'terminatedPregnancy',
                    label: t(
                      'surrogateNotice.eventToCommunicate.options.terminatedPregnancy'
                    ),
                  },
                  {
                    value: 'birth',
                    label: t(
                      'surrogateNotice.eventToCommunicate.options.birth'
                    ),
                  },
                ]}
                label={t('surrogateNotice.eventToCommunicate.selectEvent')}
                errorMessage={errors.event?.message}
              />
            </SectionContainer>

            {watch('event') === 'surrogatePregnant' && (
              <SectionContainer>
                <h2 className='heading2'>
                  {t('surrogateNotice.surrogatePregnant.heading')}
                </h2>
                <p>
                  {requestData.fileType === 'couple'
                    ? t('surrogateNotice.surrogatePregnant.paragraphCouple')
                    : t('surrogateNotice.surrogatePregnant.paragraphSingle')}
                </p>
                <Columns numOfCols={2} className='!mt-5'>
                  <RegisteredDateField
                    required
                    name={'expectedDeliveryDate'}
                    register={register}
                    label={t('surrogateNotice.surrogatePregnant.dateInput')}
                    errorMessage={errors.expectedDeliveryDate?.message}
                  />
                  <div />
                </Columns>
                <h3 className='heading3'>
                  {t('surrogateNotice.surrogatePregnant.materialUsed.heading')}
                </h3>
                {demande?.isCouple ? (
                  <RegisteredCheckboxGroup
                    label={t(
                      'surrogateNotice.surrogatePregnant.materialUsed.sperm'
                    )}
                    required
                    register={register}
                    name={'sperm'}
                    options={[
                      {
                        label: t(
                          'surrogateNotice.surrogatePregnant.materialUsed.options.parent',
                          { number: '1' }
                        ),
                        name: 'parent1',
                      },
                      {
                        label: t(
                          'surrogateNotice.surrogatePregnant.materialUsed.options.parent',
                          { number: '2' }
                        ),
                        name: 'parent2',
                      },
                      {
                        label: t(
                          'surrogateNotice.surrogatePregnant.materialUsed.options.spermDonor'
                        ),
                        name: 'donor',
                      },
                    ]}
                    errorMessage={errors.sperm?.message}
                  />
                ) : (
                  <RegisteredCheckboxGroup
                    required
                    name={'sperm'}
                    register={register}
                    options={[
                      {
                        name: 'parent1',
                        label: t(
                          'surrogateNotice.surrogatePregnant.materialUsed.options.parent',
                          { number: '' }
                        ),
                      },

                      {
                        name: 'donor',
                        label: t(
                          'surrogateNotice.surrogatePregnant.materialUsed.options.spermDonor'
                        ),
                      },
                    ]}
                    label={t(
                      'surrogateNotice.surrogatePregnant.materialUsed.sperm'
                    )}
                    errorMessage={errors.sperm?.message}
                  />
                )}
                <RegisteredRadioGroup
                  required
                  name={'egg'}
                  register={register}
                  options={
                    demande?.isCouple
                      ? [
                          {
                            value: 'parent1',
                            label: t(
                              'surrogateNotice.surrogatePregnant.materialUsed.options.parent',
                              { number: '1' }
                            ),
                          },
                          {
                            value: 'parent2',
                            label: t(
                              'surrogateNotice.surrogatePregnant.materialUsed.options.parent',
                              { number: '2' }
                            ),
                          },
                          {
                            value: 'surrogate',
                            label: t(
                              'surrogateNotice.surrogatePregnant.materialUsed.options.surrogate'
                            ),
                          },
                          {
                            value: 'eggDonor',
                            label: t(
                              'surrogateNotice.surrogatePregnant.materialUsed.options.eggDonor'
                            ),
                          },
                        ]
                      : [
                          {
                            value: 'parent1',
                            label: t(
                              'surrogateNotice.surrogatePregnant.materialUsed.options.parent',
                              { number: '' }
                            ),
                          },
                          {
                            value: 'surrogate',
                            label: t(
                              'surrogateNotice.surrogatePregnant.materialUsed.options.surrogate'
                            ),
                          },
                          {
                            value: 'eggDonor',
                            label: t(
                              'surrogateNotice.surrogatePregnant.materialUsed.options.eggDonor'
                            ),
                          },
                        ]
                  }
                  label={t(
                    'surrogateNotice.surrogatePregnant.materialUsed.egg'
                  )}
                  errorMessage={errors.egg?.message}
                />
                <p>
                  {requestData.fileType === 'couple'
                    ? t(
                        'surrogateNotice.surrogatePregnant.materialUsed.noteCouple'
                      )
                    : t(
                        'surrogateNotice.surrogatePregnant.materialUsed.noteSingle'
                      )}
                </p>
              </SectionContainer>
            )}

            {watch('event') === 'terminatedPregnancy' && (
              <SectionContainer>
                <h2 className='heading2'>
                  {t('surrogateNotice.terminatedPregnancy.heading')}
                </h2>
                <ReactMarkdown className='!mb-5 whitespace-pre-wrap'>
                  {requestData.fileType === 'couple'
                    ? t('surrogateNotice.terminatedPregnancy.paragraphCouple')
                    : t('surrogateNotice.terminatedPregnancy.paragraphSingle')}
                </ReactMarkdown>
                <Columns numOfCols={2}>
                  <RegisteredDateField
                    required
                    name={'terminationDate'}
                    register={register}
                    label={t('surrogateNotice.terminatedPregnancy.dateField')}
                    errorMessage={errors.terminationDate?.message}
                  />
                  <div />
                </Columns>
                <ReactMarkdown>
                  {t('surrogateNotice.terminatedPregnancy.reminder')}
                </ReactMarkdown>
              </SectionContainer>
            )}

            {watch('event') === 'birth' && (
              <SectionContainer>
                <h2 className='heading2'>
                  {t('surrogateNotice.birth.heading')}
                </h2>
                <p className='!mb-5'>
                  {requestData.fileType === 'couple'
                    ? t('surrogateNotice.birth.paragraphCouple')
                    : t('surrogateNotice.birth.paragraphSingle')}
                </p>
                <RegisteredSelect
                  required
                  size='sm'
                  name={'numberOfChildren'}
                  register={register}
                  label={t('surrogateNotice.birth.infantId.infantNumberSelect')}
                  options={[
                    ...Array.from({ length: 10 }, (_value, index) => index),
                  ].map((e) => ({
                    label: e + 1,
                    value: e + 1,
                  }))}
                  defaultValue={1}
                  placeholder={t('common.forms.selectPlaceholder')}
                  errorMessage={errors.numberOfChildren?.message}
                />

                {fields.map((field, i) => (
                  <div key={field.id}>
                    <h3 className='heading3'>
                      {t('surrogateNotice.birth.infantId.heading', {
                        number: numberOfChildren > 1 ? i + 1 : '',
                      })}
                    </h3>
                    <Columns numOfCols={2}>
                      <RegisteredTextField
                        required
                        name={`infantFields.${i}.lastName` as const}
                        register={register}
                        label={t(
                          'surrogateNotice.birth.infantId.fields.lastName'
                        )}
                        errorMessage={
                          errors.infantFields?.[i]?.lastName?.message
                        }
                      />
                      <RegisteredTextField
                        required
                        name={`infantFields.${i}.firstName` as const}
                        register={register}
                        label={t(
                          'surrogateNotice.birth.infantId.fields.firstName'
                        )}
                        errorMessage={
                          errors.infantFields?.[i]?.firstName?.message
                        }
                      />
                    </Columns>
                    <Columns numOfCols={2}>
                      <RegisteredSelect
                        required
                        name={`infantFields.${i}.sex` as const}
                        register={register}
                        label={t('surrogateNotice.birth.infantId.fields.sex')}
                        options={[
                          {
                            value: 'female',
                            label: t('personalInfo.parentForm.genders.female'),
                          },
                          {
                            value: 'male',
                            label: t('personalInfo.parentForm.genders.male'),
                          },
                          {
                            value: 'other',
                            label: t('personalInfo.parentForm.genders.other'),
                          },
                        ]}
                        placeholder={t('common.forms.selectPlaceholder')}
                        errorMessage={errors.infantFields?.[i]?.sex?.message}
                      />
                      <RegisteredDateField
                        required
                        name={`infantFields.${i}.dob` as const}
                        register={register}
                        label={t('surrogateNotice.birth.infantId.fields.dob')}
                        errorMessage={errors.infantFields?.[i]?.dob?.message}
                      />
                    </Columns>
                  </div>
                ))}

                <h3 className='heading3'>
                  {t('surrogateNotice.birth.placeOfBirth.heading')}
                </h3>
                <Columns numOfCols={2}>
                  <div>
                    <RegisteredTextField
                      required
                      name='municipality'
                      register={register}
                      label={t(
                        'surrogateNotice.birth.placeOfBirth.municipality'
                      )}
                      errorMessage={errors.municipality?.message}
                    />
                    <RegisteredDateField
                      required
                      name={`expectedReturnToQuebec`}
                      register={register}
                      label={t('surrogateNotice.birth.placeOfBirth.returnDate')}
                      errorMessage={errors.expectedReturnToQuebec?.message}
                    />
                  </div>
                  <RegisteredSelect
                    name={'state'}
                    register={register}
                    label={t('priorAuth.form.chosenState.select')}
                    placeholder={t('priorAuth.form.chosenState.placeholder')}
                    options={getSelectOptions('etats')}
                    errorMessage={errors?.state?.message?.toString()}
                    required
                  />
                </Columns>
                <ReactMarkdown className='whitespace-pre-wrap'>
                  {t('surrogateNotice.birth.note')}
                </ReactMarkdown>
              </SectionContainer>
            )}
          </fieldset>
          {watch().event !== '' && (
            <SectionContainer>
              <h2 className='!mt-20 !mb-0 heading2'>
                {t('surrogateNotice.signature.heading')}
              </h2>
              <Columns numOfCols={2}>
                <div className='flex flex-col'>
                  <h4 className={'heading4 !mb-2 space-x-4'}>
                    <span>
                      {selfParent?.firstName} {selfParent?.lastName}
                    </span>
                    {selfParentHasSigned ? (
                      <Pill
                        type={'success'}
                        text={t('common.signature.signedLabel')}
                      />
                    ) : (
                      <Pill
                        type={'warning'}
                        text={t('common.signature.toSignLabel')}
                      />
                    )}
                  </h4>

                  {!selfParentHasSigned ? (
                    <>
                      <RegisteredCheckbox
                        label={t('common.signature.clickToSign')}
                        name='signConsent'
                        register={register}
                      />
                      <Button
                        type={ButtonTypes.submit}
                        loading={isSubmitting}
                        disabled={isSubmitting || !watch('signConsent')}
                        label={t('changeRequest.button')}
                        className='inline mr-auto'
                        primary
                      />
                    </>
                  ) : (
                    <>
                      <p className={'!mb-8'}>
                        {t('common.signature.signed', {
                          name: `${selfParent?.firstName} ${selfParent?.lastName}`,
                          time: getFormattedDateTime(
                            selfParentHasSigned,
                            i18n.language
                          ),
                        })}
                        <BackButton />
                      </p>
                    </>
                  )}
                </div>
                {otherParent && (
                  <>
                    <div className='flex flex-col'>
                      <h4 className={'heading4 !mb-2 space-x-4'}>
                        <span>
                          {otherParent?.firstName} {otherParent?.lastName}
                        </span>
                        {otherParentHasSigned ? (
                          <Pill
                            type={'success'}
                            text={t('common.signature.signedLabel')}
                          />
                        ) : (
                          <Pill
                            type={'warning'}
                            text={t('common.signature.toSignLabel')}
                          />
                        )}
                      </h4>
                      {otherParentHasSigned ? (
                        <p className={'!mb-8'}>
                          {t('common.signature.signed', {
                            name: `${otherParent?.firstName} ${otherParent?.lastName}`,
                            time: getFormattedDateTime(
                              otherParentHasSigned,
                              i18n.language
                            ),
                          })}
                        </p>
                      ) : (
                        <p className={'!mb-8'}>
                          {t('common.signature.mustSign', {
                            name: `${otherParent?.firstName} ${otherParent?.lastName}`,
                          })}
                        </p>
                      )}
                    </div>
                  </>
                )}
              </Columns>
            </SectionContainer>
          )}
        </Form>
      </div>
    </ScreenContainer>
  );
};

export default SurrogateNotice;
