import { useTranslation } from 'react-i18next';
import SectionContainer from './containers/SectionContainer';
import ListTable from './containers/listTable/ListTable';
import { ArrowLongRightIcon } from '@heroicons/react/24/solid';
import theme from '../lib/theme';
import { Avancement, DemandeGPAClientPayload } from '../api/methods/demandes';
import Pill from './Pill';

// const stateToStatus = (state: string) => {
//   switch (state) {
//     case 'À compléter':
//       return 'warning';
//     case 'Complété':
//       return 'success';
//     default:
//       return 'default';
//   }
// };

const FormCompletion = ({
  step,
  demande,
  avancement,
}: {
  step: number;
  demande: DemandeGPAClientPayload;
  avancement?: Avancement;
}) => {
  const { t } = useTranslation();
  const availableFiles = {
    2: [
      {
        name: t('priorAuth.title'),
        link: (
          <a
            href={`/priorAuth?demandeId=${demande?.id}`}
            className='flex justify-between'
          >
            {avancement?.priorAuth === true && (
              <Pill type={'success'} text={t('common.forms.complete')} />
            )}
            {avancement?.priorAuth === false && (
              <Pill type={'warning'} text={t('common.forms.draft')} />
            )}
            {avancement?.priorAuth === undefined && (
              <span className='flex-grow inline-block md:white'>
                {t('common.seeForm')}
              </span>
            )}
            <ArrowLongRightIcon
              className='float-right !w-6'
              color={theme.colors.red[200]}
            />
          </a>
        ),
        state: '',
      },
    ],
    3: [
      {
        name: t('parentAuth.title'),
        link:
          demande?.stage?.step >= 3 ? (
            <a
              href={`/parentAuth?demandeId=${demande?.id}`}
              className='flex justify-between'
            >
              {avancement?.parentAuth === true && (
                <Pill type={'success'} text={t('common.forms.complete')} />
              )}
              {avancement?.parentAuth === false && (
                <Pill type={'warning'} text={t('common.forms.draft')} />
              )}
              {avancement?.parentAuth === undefined && (
                <span className='flex-grow inline-block md:white'>
                  {t('common.seeForm')}
                </span>
              )}
              <ArrowLongRightIcon
                className='float-right !w-6'
                color={theme.colors.red[200]}
              />
            </a>
          ) : (
            <></>
          ),
        state: '',
      },
    ],
    5: [
      {
        name: t('surrogateNotice.heading'),
        link:
          demande?.stage?.step >= 4 ? (
            <a
              href={`/surrogateNotice?demandeId=${demande?.id}`}
              className='flex justify-between'
            >
              <span className='flex-grow inline-block md:white'>
                {t('common.seeForm')}
              </span>

              <ArrowLongRightIcon
                className='float-right !w-6'
                color={theme.colors.red[200]}
              />
            </a>
          ) : (
            <></>
          ),
        state: '',
      },
    ],
  };

  const files = availableFiles[step as keyof typeof availableFiles] || [];

  return (
    <SectionContainer id={'formulaires'}>
      <div className='flex items-center justify-between !mt-16 !mb-10'>
        <h2 className='heading2 !my-0'>
          {t('dashboard.formCompletion.title')}
        </h2>
      </div>
      <ListTable
        titles={[
          t('dashboard.formCompletion.tableHeaders.form'),
          // t('dashboard.formCompletion.tableHeaders.status'),
          '',
        ]}
        elements2D={[
          ...files.map((file) => [
            <p>{file.name}</p>,
            file.link,
            // <Pill type={stateToStatus(file.state)} text={file.state} />,
          ]),
        ]}
      />
      {step > 2 && step < 7 && (
        <>
          <div className='flex flex-col !mt-16 !mb-10'>
            <h2 className='heading2 !my-0'>{t('changeRequest.list')}</h2>
            <p>{t('changeRequest.whenApplicable')}</p>
          </div>
          <ListTable
            titles={[
              t('dashboard.formCompletion.tableHeaders.form'),
              // t('dashboard.formCompletion.tableHeaders.status'),
              '',
            ]}
            elements2D={[
              [
                <p>{t('changeRequest.title')}</p>,
                demande?.stage?.step > 2 ? (
                  <a
                    href={`/changeRequest?demandeId=${demande?.id}`}
                    className='flex justify-between'
                  >
                    {avancement?.changeRequest === true && (
                      <Pill
                        type={'success'}
                        text={t('common.forms.complete')}
                      />
                    )}
                    {avancement?.changeRequest === false && (
                      <Pill type={'warning'} text={t('common.forms.draft')} />
                    )}
                    {avancement?.changeRequest === undefined && (
                      <span className='flex-grow inline-block md:white'>
                        {t('common.seeForm')}
                      </span>
                    )}
                    <ArrowLongRightIcon
                      className='float-right !w-6'
                      color={theme.colors.red[200]}
                    />
                  </a>
                ) : (
                  <></>
                ),
                // <Pill type={stateToStatus(file.state)} text={file.state} />,
              ],
            ]}
          />
        </>
      )}
    </SectionContainer>
  );
};

export default FormCompletion;
