import { ParentPayload } from '../api/methods/demandes';
import { useTranslation } from 'react-i18next';
import { useApi } from '../api/APIContext';
import { LegacyRef, useState } from 'react';
import { Alert } from '../alerts/Alert';
import { useForm } from 'react-hook-form';
import Columns from './containers/Columns';
import Select from '../forms/Select';
import RegisteredTextField from '../forms/registeredFields/RegisteredTextField';
import Form from '../forms/Form';
import Button, { ButtonTypes } from '../forms/Button';

export default function IdentityVerification({
  parent,
  formRef,
  canVerify,
}: {
  parent: ParentPayload & { isSelf: boolean };
  formRef: LegacyRef<HTMLFormElement>;
  canVerify: boolean;
}) {
  const { t } = useTranslation();
  const [error, setError] = useState<boolean>(false);
  const [justCompletedVerification, setJustCompletedVerification] =
    useState<boolean>(false);
  const api = useApi();
  const {
    handleSubmit,
    register,
    formState: { isSubmitting },
  } = useForm({});

  const parentOptions = [
    {
      value: 'father',
      label: t('identityVerification.father_select'),
    },
    {
      value: 'mother',
      label: t('identityVerification.mother_select'),
    },
  ];

  const submitFn = async (data: any) => {
    const { prenomPere, nomPere, prenomMere, nomMere } = data;
    try {
      const { appariement } = await api.demandes.appariementRamq(
        prenomPere,
        nomPere,
        prenomMere,
        nomMere
      );
      if (appariement) {
        setError(false);
        setJustCompletedVerification(true);
      } else {
        setError(true);
      }
    } catch (error) {
      setError(true);
    }
  };
  return (
    <>
      <h3 className='!mt-8 heading3'>{t('identityVerification.title')}</h3>
      {canVerify ? (
        <>
          {error && (
            <Alert type={'danger'} message={t('identityVerification.failed')} />
          )}
          {parent?.isSelf && (parent?.ramq || justCompletedVerification) && (
            <Alert
              type={'success'}
              message={t('identityVerification.completed')}
            />
          )}
          {!parent?.isSelf && !parent?.ramq && (
            <Alert
              type={'warning'}
              message={t('identityVerification.eachPI', {
                name: parent?.firstName,
              })}
            />
          )}
          {!parent?.isSelf && parent?.ramq && (
            <Alert
              type={'success'}
              message={t('identityVerification.otherPIHasCompleted', {
                name: parent?.firstName,
              })}
            />
          )}
          {parent.isSelf && !parent?.ramq && !justCompletedVerification && (
            <Form formRef={formRef} onSubmit={handleSubmit(submitFn)}>
              <p>{t('identityVerification.explanation')}</p>
              <Columns numOfCols={2} className={'!mt-4'}>
                <div className={'flex flex-col'}>
                  <Select
                    name={'typeparent1'}
                    label={''}
                    placeholder={''}
                    options={[
                      ...parentOptions,
                      {
                        value: 'other',
                        label: t('identityVerification.parent_select'),
                      },
                    ]}
                  ></Select>
                  <RegisteredTextField
                    name={'prenomPere'}
                    register={register}
                    label={t('identityVerification.first_name')}
                  />
                  <RegisteredTextField
                    name={'nomPere'}
                    register={register}
                    label={t('identityVerification.last_name')}
                  />
                </div>
                <div className={'flex flex-col'}>
                  <Select
                    name={'typeparent2'}
                    label={''}
                    placeholder={''}
                    options={[
                      ...parentOptions,
                      {
                        value: 'other',
                        label: t('identityVerification.parent_other'),
                      },
                    ]}
                  ></Select>
                  <RegisteredTextField
                    name={'prenomMere'}
                    register={register}
                    label={t('identityVerification.first_name')}
                  />
                  <RegisteredTextField
                    name={'nomMere'}
                    register={register}
                    label={t('identityVerification.last_name')}
                  />
                </div>
              </Columns>
              <Button
                label={t('identityVerification.verify_identity')}
                compact
                disabled={isSubmitting}
                loading={isSubmitting}
                type={ButtonTypes.submit}
              ></Button>
            </Form>
          )}
        </>
      ) : (
        <>
          <Alert
            type={'warning'}
            message={t('identityVerification.cantVerify')}
          />
        </>
      )}
    </>
  );
}
