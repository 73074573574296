import { dataverseFetch, dataverseFetchWithNotification } from './http';
import {
  getActiveDemandeId,
  loadActiveDemandeId,
} from '../../utils/demande.utils';
import {
  getMarriageScenario,
  MarriageScenario,
  MarriageType,
  PriorAuthPayload,
} from '../../utils/priorAuthForm.utils';
import { SignatureStatus } from '../../utils/types';

export interface PriorauthFormTypes {
  etats: ChoiceList[];
  statutconjugal: ChoiceList[];
  preuveCit: ChoiceList[];
  preuvededomicile: ChoiceList[];
  preuvedetatcivil: ChoiceList[];
  preuveunionconjugale: ChoiceList[];
  preuvevalidationpi: ChoiceList[];
  statutResidenceAuCanada: ChoiceList[];
}

export interface ParentAuthFormTypes {
  etats: ChoiceList[];
  preuvedecitoyenneteouresidenceperm: ChoiceList[];
  centredeprocreationassistelettre: ChoiceList[];
  preuvevalidationpi: ChoiceList[];
  preuvedomicile: ChoiceList[];
}

export interface SurrogateNoticeFormTypes {
  etats: ChoiceList[];
}

export interface Avancement {
  ramq: boolean;
  priorAuth: boolean;
  parentsLogic: boolean;
  changeRequest: boolean;
  parentAuth: boolean;
  blockSubmission: boolean;
}

export const deleteDemande = async () => {
  const demandeId = getActiveDemandeId();
  return dataverseFetch(`/demandes/${demandeId}`, {
    method: 'DELETE',
  });
};
export const getPriorAuthFormTypes = async () =>
  dataverseFetch<PriorauthFormTypes>('/priorAuth/values');

export const getParentAuthFormTypes = async () =>
  dataverseFetch<ParentAuthFormTypes>('/parentAuth/values');

export const getSurrogateNoticeFromTypes = async () =>
  dataverseFetch<SurrogateNoticeFormTypes>('/surrogateNotice/values');

export const getPersonalInfoFormTypes = async () =>
  dataverseFetch<ChoiceList[]>('/values/alletats');
export const getDemandes = async () =>
  dataverseFetch<DemandeGPAClientPayload[]>('/demandes');

export const getActivedemande = async (forceDemandeId?: string) => {
  const demandeId = forceDemandeId ?? getActiveDemandeId();

  if (!demandeId) {
    return undefined;
  } else {
    return dataverseFetch<DemandeGPAClientPayload>(`/demandes/${demandeId}`);
  }
};

export const appariementRamq = async (
  prenomPere: string,
  nomPere: string,
  prenomMere: string,
  nomMere: string
) => {
  return dataverseFetch<{ appariement: boolean }>(`/appariementRamq`, {
    method: 'POST',
    body: JSON.stringify({ prenomPere, nomPere, prenomMere, nomMere }),
  });
};

export const getParents = async () => {
  const demandeId = getActiveDemandeId();

  if (!demandeId) {
    return [];
  } else {
    return dataverseFetch<(ParentPayload & { isSelf: boolean })[]>(
      `/demandes/${demandeId}/parents`
    );
  }
};

export const getAvancement = async () => {
  const demandeId = getActiveDemandeId();

  if (!demandeId) {
    return undefined;
  } else {
    return dataverseFetch<Avancement>(`/demandes/${demandeId}/avancement`);
  }
};

export const setAvancement = async (avancement: number) => {
  const demandeId = getActiveDemandeId();

  return dataverseFetchWithNotification<{ step: number }>(
    `/demandes/${demandeId}/avancement`,
    {
      method: 'PUT',
      body: JSON.stringify({ step: avancement }),
    }
  );
};

export const saveParent = async (
  parentId: string,
  parent: ParentSavePayload
) => {
  const demandeId = loadActiveDemandeId();

  return dataverseFetchWithNotification<ParentPayload>(
    `/demandes/${demandeId}/parents/${parentId}`,
    {
      method: 'PUT',
      body: JSON.stringify(parent),
    }
  );
};

export const savePiUnique = async (unique: boolean) => {
  const demandeId = loadActiveDemandeId();

  return dataverseFetchWithNotification(`/linking/${demandeId}/setPIUnique`, {
    method: 'PUT',
    body: JSON.stringify({ unique }),
  });
};

export const getPIUnique = async () => {
  const demandeId = loadActiveDemandeId();

  return dataverseFetch<boolean>(`/linking/${demandeId}/getPIUnique`);
};

export const pingDemande = async () => {
  const demandeId = getActiveDemandeId();
  if (demandeId) {
    return dataverseFetch(`/demandes/${demandeId}/ping`);
  }
};

export const getPriorAuth = async () => {
  const demandeId = loadActiveDemandeId();

  return dataverseFetch<PriorAuthPayload>(`/demandes/${demandeId}/priorAuth`);
};

export const getParentAuth = async () => {
  const demandeId = loadActiveDemandeId();

  return dataverseFetch<ParentAuthForm>(`/demandes/${demandeId}/parentAuth`);
};
export const savePriorAuth = async (priorAuth: any) => {
  const demandeId = loadActiveDemandeId();

  return dataverseFetchWithNotification<ParentPayload>(
    `/demandes/${demandeId}/priorAuth`,
    {
      method: 'PUT',
      body: JSON.stringify(priorAuth),
    }
  );
};

export const saveParentAuth = async (parentAuth: ParentAuthForm) => {
  const demandeId = loadActiveDemandeId();

  return dataverseFetchWithNotification<ParentPayload>(
    `/demandes/${demandeId}/parentAuth`,
    {
      method: 'PUT',
      body: JSON.stringify(parentAuth),
    }
  );
};

export const getLinkingInformation = async (): Promise<{
  code: string;
  isUnique: boolean;
  canLink: boolean;
}> => {
  const demandeId = loadActiveDemandeId();

  return dataverseFetch<{ code: string; isUnique: boolean; canLink: boolean }>(
    `/linking/${demandeId}`
  );
};

export const getPriorauthForDemande = async () => {
  const demandeId = loadActiveDemandeId();

  const priorAuthClientPayload = await dataverseFetch<PriorAuthClientPayload>(
    `/demandes/${demandeId}/priorAuth`
  );

  priorAuthClientPayload.scenario = getMarriageScenario(
    priorAuthClientPayload.marriageType
  );

  return priorAuthClientPayload;
};

export const getPriorAuthSigningStatus = async () => {
  const demandeId = loadActiveDemandeId();

  return dataverseFetch<SignatureStatus>(
    `/demandes/${demandeId}/priorAuth/signature`
  );
};

export const getParentAuthSigningStatus = async () => {
  const demandeId = loadActiveDemandeId();

  return dataverseFetch<SignatureStatus>(
    `/demandes/${demandeId}/parentAuth/signature`
  );
};

export const signPriorAuth = async (formData: PriorAuthClientPayload) => {
  const demandeId = loadActiveDemandeId();

  return dataverseFetchWithNotification<SignatureStatus>(
    `/demandes/${demandeId}/priorAuth/signature`,
    {
      method: 'POST',
      body: JSON.stringify(formData),
    }
  );
};

export const signParentAuth = async (parentAuth: ParentAuthForm) => {
  const demandeId = loadActiveDemandeId();

  return dataverseFetchWithNotification<SignatureStatus>(
    `/demandes/${demandeId}/parentAuth/signature`,
    {
      method: 'POST',
      body: JSON.stringify(parentAuth),
    }
  );
};

export const createDemande = async () => {
  return dataverseFetchWithNotification<{ demandeId: string }>('/demandes', {
    method: 'POST',
  });
};

export interface ParentOnDemande {
  id: string;
  firstName: string;
  lastName: string;
  isSelf: boolean;
  email?: string;
}

export interface ParentPayload {
  id: string;
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  postalCode: string;
  dob: string;
  email?: string;
  telephone?: string;
  nam?: string;
  gender?: number;
  sex?: number;
  ramq?: boolean;
  pays?: string;
  ville?: string;
  preferredLanguage?: number;
}
export type ParentSavePayload = Omit<ParentPayload, 'id'>;

export interface DemandeGPAClientPayload {
  id: string;
  fileNumber: string;
  createdAt: Date;
  modifiedAt: Date;
  stage: { step: number; status: 'draft' | 'submitted' | 'completed' };
  parents?: ParentOnDemande[];
  isCouple: boolean;
  isClosed: boolean;
  piUnique: boolean;
}

export interface PriorAuthClientPayload {
  scenario: MarriageScenario;
  chosenState?: string;
  dateOfDeclaration?: string;
  declarationNumber?: string;
  marriageType: MarriageType;
  parent1: PriorAuthParent;
  parent2?: PriorAuthParent;
  marriageDocument?: string;
  signConsent: boolean;
}

export interface PriorAuthParent {
  id?: string;
  firstName?: string;
  lastName?: string;
  residence?: string;
  idProof1?: CitizenshipProof;
  idProof2?: CitizenshipProof;
  residenceProofType1?: string;
  residenceProofType2?: string;
  citizenshipProof?: CitizenshipProof;
  certOfAttendanceDate?: string;
  criminalRecordCheckDate?: string;
  consent?: string;
  signature?: boolean;
}

export interface CitizenshipProof {
  docType?: string;
  expiration?: string;
}

export interface ParentAuthForm {
  changes: {
    status:
      | ''
      | 'undeterminedChanges'
      | 'noChanges'
      | 'hasMaritalStatusChanges'
      | 'hasResidenceChanges'
      | 'hasMaritalAndResidenceChanges';
    hasChanges: 'yes' | 'no' | '';
    changes: string[];
    changeAttachedDoc?: string;
    residenceChangeDoc?: string;
    parentChangeExplanation?: string;
  };

  surrogate: {
    lastName: string;
    firstName: string;
    dob: string;
    address: string;
    city: string;
    state: string;
    postalCode: string;
    telephone: string;
    email: string;
  };

  documents: {
    idDocType: string;
    idDocExpiration: string;
    addressDocType: string;
    addressDocExpiration: string;
  };

  altruism: boolean;

  ethnicOrigin: string;
  education: string;
  diplomas: string;
  profession: string;

  height: string;
  eyeColour: string;
  skinColour: string;
  hairColour: string;
  personalityTraits: string;
  motivations: string;

  surrogateRelationship: {
    maritalStatus: '' | 'single' | 'civilUnion' | 'commonLaw' | 'married';
    spouseFamilyName?: string;
    spouseName?: string;
  };

  surrogateMaterialUsed: 'yes' | 'no';

  reproductiveMaterial: {
    parent1: 'ovocyte' | 'sperm' | 'none';
    parent2: 'ovocyte' | 'sperm' | 'none';
    needsDonationMaterial: 'yes' | 'no';
    donationMaterial?: 'ovocyte' | 'sperm' | 'spermAndOvocyte';
  };
  reproductiveMaterialDocType: string;
  reproductiveMaterialDocExpiration: string;

  agreement: {
    agreementDate: string;
    language: {
      isInFrench: '' | 'yes' | 'no';
      agreementLanguage?: string;
    };
    reimbursement: {
      isReimbursed: '' | 'yes' | 'no';
      signDate?: string;
    };
    authorizationSignDate: string;
  };

  // Ignored, client-side only field
  signConsent: boolean | undefined;
}
